import { useContext, useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';
import { pixelRatios } from 'constants/images';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import { LightingSelectorTitles } from 'pages/generation/components/Lighting/components/Selectors';

interface Generations {
  id: string;
  images: [];
  status: string;
}

export interface GenerateBody {
  clothing: string;
  activity: string;
  presetOptionIds: string[];
  makeup: boolean;
  height: number;
  width: number;
  numberOfImages: number;
}

const useGenerate = () => {
  const { activity, clothing } = useContext(GenerationContext);
  const { numberOfVariations, ratios } = useContext(RenderSettingsContext);
  const { age, gender, ethnicity } = useContext(DemographicsContext);
  const { shot, lighting, lightingType, cameraType } = useContext(LightingContext);
  const { eyesColor, hairColor, skinTone, appearance, body, makeup, expression, facialHair, shavedHead } =
    useContext(PhysicalDetailsContext);
  const { error, data, mutate, isPending } = useMutation<Generations, AxiosError, any>({
    mutationFn: (body: GenerateBody) =>
      api.post<{ result: Generations }>(Queries.GENERATIONS, body).then(res => res.data.result),
  });

  // const parsedError: string | undefined | null = error?.response?.data?.error?.message ?? error?.message;
  const options = [
    eyesColor?.value,
    hairColor?.value,
    skinTone?.value,
    appearance?.value,
    makeup?.value,
    expression?.value,
    facialHair?.value,
    shot?.value,
    lighting?.value,
    ethnicity?.value,
    body?.value,
    age?.value,
    gender?.value,
    shavedHead?.value,
    lightingType?.label !== LightingSelectorTitles.BLACK_AND_WHITE_SETTINGS ? cameraType?.value : undefined,
  ].filter(i => i !== undefined);
  const generate = (presetBody?: GenerateBody) => {
    mutate(
      presetBody
        ? presetBody
        : {
            clothing: clothing,
            activity: activity,
            presetOptionIds: [...options],
            height: ratios && pixelRatios[ratios].height,
            width: ratios && pixelRatios[ratios].width,
            numberOfImages: numberOfVariations,
          },
    );
  };

  useEffect(() => {
    if (error) {
      console.log(`Error: useSendResetInstructions - ${error.message}`);
    }
  }, [error]);

  return { error, data, generate, isPending };
};

export default useGenerate;
