import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';
import { DemographicsContext } from 'context/DemographicsContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

export interface Draft {
  clothing: string;
  height?: number;
  activity: string;
  makeup: boolean;
  numberOfImages?: 2 | 4;
  presetOptionIds: string[];
  width?: number;
}

const useDraft = () => {
  const { numberOfVariations, ratios } = useContext(RenderSettingsContext);
  const { age, gender, ethnicity } = useContext(DemographicsContext);
  useContext(DemographicsContext);
  const { cameraType, shot, lighting } = useContext(LightingContext);
  const { eyesColor, hairColor, skinTone, appearance, body, makeup, expression, facialHair } =
    useContext(PhysicalDetailsContext);
  const { isLoading, data, error, refetch } = useQuery<Draft, AxiosError>({
    queryKey: [
      'draft',
      eyesColor,
      cameraType,
      hairColor,
      shot,
      lighting,
      ethnicity,
      age,
      ratios,
      skinTone,
      appearance,
      body,
      makeup,
      expression,
      facialHair,
      numberOfVariations,
      gender,
    ],
    queryFn: () =>
      api.get<{ result: Draft }>(Queries.GENERATION_DRAFTS_CURRENT).then(res => {
        return res.data.result;
      }),
  });

  return { isLoading, error, data, refetch };
};

export default useDraft;
