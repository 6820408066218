import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';

const useBackgroundImage = () => {
  const { isLoading, data, error, refetch } = useQuery<any, AxiosError>({
    queryKey: ['background-image'],
    queryFn: () =>
      api.get<any>(Queries.BACKGROUND_IMAGE).then(res => {
        return res.data;
      }),
  });

  return { isLoading, error, data, refetch };
};

export default useBackgroundImage;
