import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from 'assets/svg/arrow-right-icon';
import SecondaryButton from 'components/SecondaryButton';
import Spinner from 'components/Spinner';
import TextInput from 'components/TextInput';
import { GenerationStatuses } from 'constants/statuses';
import { GenerationContext } from 'context/GenerationContext';
import { NewGenerationContext } from 'context/NewGenerationContext';

import css from './index.module.scss';

import AlfaChannelBlock from '../AlfaChannelBlock';
import UpscaledBlock from '../UpscaledBlock';

import cssArrow from '../../index.module.scss';

interface Props {
  currentImageIndex: number;
  setCurrentImageIndex: Dispatch<SetStateAction<number>>;
  generateNew: (imgId: string, clothing: string, activity: string) => void;
}

export enum States {
  EDIT = 'edit',
  UPSCALE = 'upscale',
  NEW_GENERATE = 'newGenerate',
}

const Edit: FC<Props> = ({ currentImageIndex, setCurrentImageIndex, generateNew }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(States.EDIT);
  const { generatedData, activity, clothing, setUpscaled, upscaled } = useContext(GenerationContext);
  const [newClothing, setNewClothing] = useState<string>(clothing);
  const [newActivity, setNewActivity] = useState<string>(activity);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isGeneratedAfla, setIsGeneratedAfla] = useState<boolean>(false);
  const { generatedData: newGeneratedData, resetGenerated, generationStatus } = useContext(NewGenerationContext);

  const isLoading = useMemo(
    () => state === States.NEW_GENERATE && generationStatus !== GenerationStatuses.FETCHED,
    [generationStatus, state],
  );

  const goToTheImage = (index: number) => {
    setCurrentImageIndex(index);
  };

  const onRegenerate = () => {
    resetGenerated();
    setState(States.NEW_GENERATE);
    setUpscaled([]);
    generateNew(generatedData?.images[currentImageIndex].id || '', newClothing, newActivity);
  };

  if (!generatedData?.images[currentImageIndex]) return null;

  useEffect(() => {
    if (upscaled[currentImageIndex]?.images?.[1]) {
      setIsGeneratedAfla(true);
    }
  }, [upscaled]);

  return (
    <div className={css.edit_image_block}>
      <div className={cssArrow.navigate_arrows}>
        <ArrowRightIcon
          onClick={() => {
            if (isGeneratedAfla) {
              setIsGeneratedAfla(false);
            } else {
              if (!generatedData.images[currentImageIndex - 1]) return;
              setState(States.EDIT);
              goToTheImage(currentImageIndex - 1);
            }
          }}
          className={`${cssArrow.navigate_arrow} ${cssArrow.navigate_arrow_left} ${(!generatedData.images[currentImageIndex - 1] || isLoading || disabled) && cssArrow.disabled}`}
        />
        {!isGeneratedAfla && (
          <ArrowRightIcon
            onClick={() => {
              if (!generatedData.images[currentImageIndex + 1]) return;
              setState(States.EDIT);
              goToTheImage(currentImageIndex + 1);
            }}
            className={`${cssArrow.navigate_arrow} ${(!generatedData.images[currentImageIndex + 1] || isLoading || disabled) && cssArrow.disabled}`}
          />
        )}
      </div>
      <div className={css.edit_generated__action_block}>
        <div style={{ display: 'flex', gap: 20, flexDirection: 'row' }}>
          <UpscaledBlock
            isShowAlfa={isGeneratedAfla}
            currentImageIndex={currentImageIndex}
            setDisabled={setDisabled}
            image={newGeneratedData?.images[0]}
            isLoading={generationStatus === GenerationStatuses.FETCHING}
          />
        </div>
        <div className={`${css.edit_generated__prompts_block}`}>
          {/* <AlfaChannelBlock
            isNewGenerated={generationStatus === GenerationStatuses.FETCHING || !!newGeneratedData?.images[0]}
            currentImageIndex={currentImageIndex}
            setDisabled={setDisabled}
            isShowAlfa={isGeneratedAfla}
            setIsShowAfla={setIsGeneratedAfla}
          /> */}
          <div className={css.edit_inputs_block}>
            <TextInput
              label={t('global.clothing')}
              value={newClothing}
              disabled={isLoading}
              onChange={setNewClothing}
              placeholder={t('clothingLocationAndActivityGoesHere...')}
            />
            <TextInput
              label={t('editModal.activity')}
              value={newActivity}
              disabled={isLoading}
              onChange={setNewActivity}
              placeholder={t('generate.whatYouDontWantGoesHere...')}
            />
            <SecondaryButton
              disabled={newClothing.length === 0 || newActivity.length === 0 || isLoading}
              className={css.edit_action_btn}
              onClick={onRegenerate}
              title={t('editModal.regenerate')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
