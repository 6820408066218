import { CSSProperties, FC } from 'react';

interface Props {
  style?: CSSProperties | undefined;
}

const BigBlueArrow: FC<Props> = ({ style }) => (
  <svg width="88" height="69" viewBox="0 0 88 69" style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M87.2732 11.4895L82.0688 1.95183L2.58845 45.3222L7.7929 54.8599L87.2732 11.4895Z" fill="#00C2FF" />
    <path d="M52.522 68.0249L55.586 57.6007L3.46486 42.2805L0.400839 52.7048L52.522 68.0249Z" fill="#00C2FF" />
    <path d="M10.8251 55.7688L0.400839 52.7048L15.721 0.583598L26.1452 3.64763L10.8251 55.7688Z" fill="#00C2FF" />
  </svg>
);

export default BigBlueArrow;
