import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { t } from 'i18next';

import useDeleteDraft from 'api/queries/useDeleteDraft';
import useGenerate from 'api/queries/useGenerate';
import useUpdateDraft from 'api/queries/useUpdateDraft';
import CheckIcon from 'assets/svg/check-icon';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import TextInput from 'components/TextInput';
import { GenerationStatuses } from 'constants/statuses';
import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

import './index.scss';

const GenerateBlock: FC = () => {
  const {
    activity,
    clothing,
    setActivity,
    setClothing,
    generationStatus,
    waitGeneratedImages,
    isReadyToGenerate,
    resetGenerated,
    setUpscaled,
    isReseted,
    setIsReseted,
  } = useContext(GenerationContext);
  const { isUserFirstTime, setIsUserFirstTime } = useContext(AuthContext);
  const { iseRenderSettingsReady, resetAllStates: resetRender } = useContext(RenderSettingsContext);
  const { isDemographicsReady, resetAllStates: resetDemographics } = useContext(DemographicsContext);
  const { isLightingReady, resetAllStates: resetLighting } = useContext(LightingContext);
  const { isPhysicalDetailsReady, resetAllStates: resetDetails } = useContext(PhysicalDetailsContext);
  const isFirstTimeUserReady = isPhysicalDetailsReady && isReadyToGenerate;
  const isReady = iseRenderSettingsReady && isDemographicsReady && isLightingReady && isFirstTimeUserReady;
  const isReadyAllTabs = iseRenderSettingsReady && isDemographicsReady && isLightingReady && isPhysicalDetailsReady;

  const { updateDraft } = useUpdateDraft();
  const { deleteDraft } = useDeleteDraft();
  const { data, isPending, generate } = useGenerate();

  const isInputsDisabled = generationStatus !== GenerationStatuses.NONE;

  const onReset = () => {
    resetRender();
    resetDemographics();
    resetDetails();
    resetLighting();
    setIsReseted(true);
    setUpscaled([]);
    setClothing('');
    setActivity('');
    resetGenerated();
  };

  const onGenerate = () => {
    generate();
    isUserFirstTime && setIsUserFirstTime(false);
    deleteDraft();
  };
  const addStyles = generationStatus !== GenerationStatuses.FETCHED ? { opacity: 0, cursor: 'default' } : {};

  useEffect(
    () => () => {
      setIsReseted(false);
    },
    [],
  );

  useEffect(() => {
    if (data?.status === 'PENDING') {
      waitGeneratedImages();
    }
  }, [data?.status]);

  return (
    <div className="generate-block">
      <div className="actions-block">
        <p
          style={{ opacity: generationStatus === GenerationStatuses.FETCHED || isReseted ? 1 : 0 }}
          className={`upscale-description ${isReseted ? 'after-reset' : ''}`}
        >
          {t(isReseted ? 'generate.upscaleDescriptionAfterReset' : 'generate.upscaleDescription')}
        </p>
        <div className="buttons_block">
          <SecondaryButton
            disabled={generationStatus !== GenerationStatuses.FETCHED}
            title={t('global.reset')}
            onClick={onReset}
            className="generate-block-edit-settings"
            style={{
              width: 133,
              justifySelf: 'center',
              ...addStyles,
            }}
          />
          <SecondaryButton
            title={t('global.editSettingsToRegenerate')}
            onClick={() => {
              setUpscaled([]);
              resetGenerated();
            }}
            className="generate-block-edit-settings"
            style={{ width: 290, justifySelf: 'center', ...addStyles }}
          />
        </div>
      </div>
      {isReadyAllTabs && (
        <div className="generate-block-inputs">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {clothing && (
              <div className="generate-block-input-check-icon_container">
                <CheckIcon />
              </div>
            )}
            <TextInput
              label={t('global.clothing')}
              value={clothing}
              onBlur={() => {
                updateDraft({ clothing, activity });
              }}
              onChange={setClothing}
              placeholder={t('generate.clothingGoesHere...')}
              disabled={isInputsDisabled}
              className="generate-block-inputs-text-input"
            />
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {activity && (
                <div className="generate-block-input-check-icon_container">
                  <CheckIcon />
                </div>
              )}
              <TextInput
                label={t('global.activityAndLocations')}
                value={activity}
                onBlur={() => {
                  updateDraft({ clothing, activity });
                }}
                onChange={setActivity}
                placeholder={t('generate.activityAndLocationGoHere...')}
                disabled={isInputsDisabled}
                className="generate-block-inputs-text-input"
              />
            </div>
          </div>

          {generationStatus !== GenerationStatuses.FETCHED ? (
            <PrimaryButton
              onClick={onGenerate}
              title={generationStatus === GenerationStatuses.FETCHING ? t('generate.generating') : t('global.generate')}
              disabled={
                !(isUserFirstTime ? isFirstTimeUserReady : isReady) ||
                isPending ||
                generationStatus !== GenerationStatuses.NONE
              }
              className="generate-block-generate-button"
            />
          ) : (
            <PrimaryButton
              onClick={() => {
                setUpscaled([]);
                resetGenerated(true);
                onGenerate();
              }}
              title={t('generate.regenerateWithoutEdits')}
              disabled={isPending}
              className="generate-block-generate-button"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateBlock;
