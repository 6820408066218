import { FC } from 'react';

import { t } from 'i18next';

import inspirationPerson from 'assets/png/InspirationPersona.png';

import CreationGuideBanner from './components/CreationGuideBanner';
import GenerationGuideBanner from './components/GenerationGuideBanner';
import IntroBanner from './components/IntroBanner';
import PhysicalDetailsBanner from './components/PhysicalDetailsBanner';
import './index.scss';

interface Props {
  isPhysicalDetailsClicked: boolean;
  isCrossPressed: boolean;
  isPhysicalDetailsReady: boolean;
}

const Banner: FC<Props> = ({ isPhysicalDetailsClicked, isCrossPressed, isPhysicalDetailsReady }) => (
  <div className="banner-container">
    <div className={'banner-row'}>
      {!isCrossPressed && <img src={inspirationPerson} className="banner-image" alt="bannerImage" />}
      {isPhysicalDetailsClicked ? (
        isCrossPressed && isPhysicalDetailsReady ? (
          <PhysicalDetailsBanner />
        ) : isCrossPressed ? (
          <GenerationGuideBanner />
        ) : (
          <CreationGuideBanner />
        )
      ) : (
        <IntroBanner />
      )}
    </div>
  </div>
);

export default Banner;
