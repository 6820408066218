import { FC, useContext } from 'react';

import { t } from 'i18next';

import SecondaryButton from 'components/SecondaryButton';
import { AuthContext } from 'context/AuthContext';

import './index.scss';

const IntroBanner: FC = () => {
  const { setIsUserFirstTime } = useContext(AuthContext);
  return (
    <div className="banner-column">
      <div className="banner-row-end">
        <div className="banner-description">
          <p className="banner-text banner-finish">
            {t('firstTimeGeneration.introBanner.finishAPersona')}
            <span className="banner-text banner-contrast">
              {' '}
              {t('firstTimeGeneration.introBanner.physicalDetails')}
            </span>{' '}
            {t('firstTimeGeneration.introBanner.toStart')}
          </p>
        </div>
        <p className="banner-text banner-or">{t('firstTimeGeneration.introBanner.or')}</p>
        <p className="banner-text banner-diy">{t('firstTimeGeneration.introBanner.startFresh')}</p>
      </div>
      <div className="banner-row-end">
        <a className="banner-link">{t('firstTimeGeneration.introBanner.personaByStudioX')}</a>
        <SecondaryButton title="Click to DIY" onClick={() => setIsUserFirstTime(false)} className="banner-diy-button" />
      </div>
    </div>
  );
};

export default IntroBanner;
