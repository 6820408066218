import { FC, useContext } from 'react';

import { t } from 'i18next';

import { GenerationStatuses } from 'constants/statuses';
import { AuthContext } from 'context/AuthContext';
import { GenerationContext } from 'context/GenerationContext';

import './index.scss';

interface Props {
  isLoading: boolean;
}

const PageTitle: FC<Props> = ({ isLoading }) => {
  const { generationStatus, activeTabIndex } = useContext(GenerationContext);
  const { isUserFirstTime } = useContext(AuthContext);
  if (isLoading) {
    return <div style={{ height: 44 }}></div>;
  }

  return (
    <>
      {isUserFirstTime ? (
        <p className="generate__page-title">
          {activeTabIndex === 6 || activeTabIndex === 3
            ? t('firstTimeGeneration.banner.inspirationPersona')
            : t('firstTimeGeneration.banner.ready')}
        </p>
      ) : (
        <p className="generate__page-title">
          {activeTabIndex === 6
            ? t('global.helloAgain')
            : generationStatus === GenerationStatuses.NONE
              ? t('global.settings')
              : generationStatus === GenerationStatuses.FETCHING
                ? t('global.rendering')
                : t('global.results')}
        </p>
      )}
    </>
  );
};

export default PageTitle;
