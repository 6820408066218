import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

import { Variations } from 'api/queries/useVariations';
import { GenerationStatuses } from 'constants/statuses';
import { useUpdates } from 'hooks/useUpdates';

export type GeneratedData = {
  id: string;
  images: { id: string; url: string }[];
  status: string;
};

export type UpscaledData = {
  images?: { url?: string; type: Variations }[];
};

interface Context {
  generatedData: GeneratedData | undefined;
  resetAllStates: () => void;
  setGenerationStatus: Dispatch<SetStateAction<GenerationStatuses>>;
  upscaled: UpscaledData[];
  setUpscaled: Dispatch<SetStateAction<UpscaledData[]>>;
  generationStatus: GenerationStatuses;
  waitGeneratedImages: () => void;
  resetGenerated: (isRefetch?: boolean) => void;
  isReadyToGenerate: boolean;
  activity: string;
  setActivity: Dispatch<SetStateAction<string>>;
  clothing: string;
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  setClothing: Dispatch<SetStateAction<string>>;
  isStarted: boolean;
  setIsStarted: Dispatch<SetStateAction<boolean>>;
  isReseted: boolean;
  setIsReseted: Dispatch<SetStateAction<boolean>>;
}

export enum Presets {
  EYE = 'Eye color',
  HAIR = 'Hair color',
  LIGHTING = 'Lighting',
  SKIN_TONE = 'Skin tone',
}

export enum GeneralPresets {
  AGE = 'Age',
  ETHNICITY = 'Ethnicity',
  GENDER = 'Gender',
  FACE_IMPRESSION = 'Face Impression',
  BODY_TYPE = 'Body Type',
}

export const GenerationContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

export const GenerationProvider: FC<Props> = ({ children }) => {
  const [clothing, setClothing] = useState<string>('');
  const [activity, setActivity] = useState<string>('');
  const [generatedData, setGeneratedData] = useState<GeneratedData>();
  const [upscaled, setUpscaled] = useState<UpscaledData[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(6);
  const [isStarted, setIsStarted] = useState(false);
  const [isReseted, setIsReseted] = useState(false);

  const { setGenerationStatus, generationStatus, resetGenerated, waitGeneratedImages } =
    useUpdates<GeneratedData>(setGeneratedData);

  const isReadyToGenerate = clothing !== null && clothing.length !== 0 && activity !== null && activity.length !== 0;

  const resetAllStates = () => {
    setUpscaled([]);
    setClothing('');
    setActivity('');
  };

  return (
    <GenerationContext.Provider
      value={{
        isReseted,
        setIsReseted,
        setGenerationStatus,
        clothing,
        setClothing,
        activeTabIndex,
        setActiveTabIndex,
        isStarted,
        setIsStarted,
        generationStatus,
        generatedData,
        setUpscaled,
        upscaled,
        activity,
        setActivity,
        resetAllStates,
        isReadyToGenerate,
        resetGenerated,
        waitGeneratedImages,
      }}
    >
      {children}
    </GenerationContext.Provider>
  );
};
